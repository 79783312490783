import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Box, Text } from "../../components/Core"
import GMFModal from "../../components/GMFModal"
import Label from "../../components/Label"
import GlobalContext from "../../context/GlobalContext"
import Vector from "../../images/svg/icons/Vector.svg"
import * as styles from "./pricing.module.css"

export default () => {
  const [openModal, setOpenModal] = useState(false)
  const gContext = useContext(GlobalContext)

  const handleGmfClic = () => {
    setOpenModal(true)
  }

  const handleGmfModalClose = () => {
    setOpenModal(false)
  }

  return (
    <Box id="pricing">
      <StaticQuery
        query={graphql`
          query {
            pricingImage: file(
              relativePath: { eq: "pricing/worker_adobespark_1.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            imageForm: file(relativePath: { eq: "pricing/image-form.png" }) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            imageFormMobile: file(
              relativePath: { eq: "pricing/bg-form-mobile.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
          }
        `}
        render={data => {
          return (
            <Container css={{ paddingBottom: "150px" }}>
              <Row>
                <Col lg={12} className={styles.sectionTitleWrapper}>
                  <Text
                    fontSize={[
                      "1.8rem",
                      "1.9rem",
                      "2.0rem",
                      "2.1rem",
                      "2.5rem",
                    ]}
                    textAlign={"center"}
                    color="primary"
                    opacity={1}
                    fontWeight={"bold"}
                    lineHeight={["50px"]}
                  >
                    Precio
                    <span
                      className={`${styles.sectionTitleDot} ${
                        gContext.isInteractuar && styles.textBlue
                      }`}
                    >
                      .
                    </span>
                  </Text>
                </Col>
              </Row>
              <Row className={styles.sectionSubtitleRow}>
                <Col lg={6} md={12} className="">
                  <div className="position-relative mb-5">
                    <div className="img-1">
                      <GatsbyImage
                        alt=""
                        className={styles.image}
                        image={getImage(data.pricingImage)}
                      />
                    </div>
                    <Box className={`d-none d-md-block ${styles.imageForm}`}>
                      <GatsbyImage alt="" image={getImage(data.imageForm)} />
                    </Box>
                    <Box
                      className={`d-md-none d-block ${styles.imageFormMobile}`}
                    >
                      <GatsbyImage
                        alt=""
                        image={getImage(data.imageFormMobile)}
                      />
                    </Box>
                    <Box
                      className={styles.imageBox}
                      css="
                      animation: movePriceshow 1.8s linear infinite;
                      @keyframes movePriceshow {
                        0% {
                          transform: translateY(-8px);
                        }
                        50% {
                          transform: translateY(0px);
                        }
                        100% {
                          transform: translateY(-8px);
                        }

                       
                      }
                      "
                    >
                      <Row>
                        <Col xs={12} css={{ maxHeight: "12px" }}>
                          <span className={styles.imagePriceLineTrough}>
                            $37.000
                          </span>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs={12}>
                          <span
                            className={`${styles.imagePrice} ${
                              gContext.isInteractuar && styles.textBlue
                            }`}
                          >
                            $30.000
                          </span>
                          <sup className={styles.imagePriceSmall}> COP</sup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <span className={styles.imagePriceSmall}>+ GMF*</span>
                        </Col>
                      </Row>
                    </Box>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <Row>
                    <Col lg={12} css="margin-bottom: 30px">
                      <span className={styles.sectionSubtitle}>Incluye:</span>
                    </Col>
                    <Row>
                      <Col lg={6} md={12}>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.boldText}>
                            Registro{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>
                            de proveedor y diligenciamiento de formularios.
                          </span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.boldText}>
                            Facturación{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>
                            en representación tuya.
                          </span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.normalText}>
                            Liquidación y pago de seguridad social{"\u00A0"}
                          </span>
                          <span className={styles.boldText}>a un clic.</span>
                          {gContext.isInteractuar ? (
                            ""
                          ) : (
                            <Label color="orange" className="outlined">
                              Beta
                            </Label>
                          )}
                        </Col>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.boldText}>
                            Anticipo{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>
                            de tu dinero.**
                          </span>
                          {gContext.isInteractuar ? (
                            ""
                          ) : (
                            <Label color="orange" className="outlined">
                              Beta
                            </Label>
                          )}
                        </Col>
                      </Col>
                      <Col lg={6} md={12}>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.normalText}>
                            Envío de{"\u00A0"}
                          </span>
                          <span className={styles.boldText}>
                            documentos{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>
                            a la empresa que te contrata.
                          </span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.boldText}>
                            Gestión{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>
                            de cobranza y seguimiento de tu pago.
                          </span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.budget}></span>
                        </Col>
                        <Col lg={12}>
                          <span className={styles.boldText}>
                            Asesoría{"\u00A0"}
                          </span>
                          <span className={styles.normalText}>tributaria.</span>
                        </Col>
                      </Col>
                      <Col lg={12} className={styles.linkWrapper}>
                        <a onClick={handleGmfClic} css={{ cursor: "pointer" }}>
                          <Row className={styles.linkRow}>
                            <Col xs={1}>
                              <span>
                                <img
                                  src={Vector}
                                  alt=""
                                  className={styles.iconImage}
                                />
                              </span>
                            </Col>
                            <Col lg={10} xs={10}>
                              <span className={styles.linkTextWithAction}>
                                *GMF: gravamen a los movimientos financieros
                              </span>
                            </Col>
                          </Row>
                        </a>
                        <a
                          href="https://app.factcil.com/terms-of-service"
                          target="__blank"
                          css={{ cursor: "pointer" }}
                        >
                          <Row className={styles.linkRow}>
                            <Col xs={1}>
                              <span>
                                <img
                                  src={Vector}
                                  alt=""
                                  className={styles.iconImage}
                                />
                              </span>
                            </Col>
                            <Col lg={10} xs={10}>
                              <span className={styles.linkTextWithAction}>
                                **Aplican términos y condiciones
                              </span>
                            </Col>
                          </Row>
                        </a>
                        <Row className={styles.linkRow}>
                          <Col xs={1}>
                            <span>
                              <img
                                src={Vector}
                                alt=""
                                className={styles.iconImage}
                              />
                            </span>
                          </Col>
                          <Col lg={10} xs={10}>
                            <span className={styles.linkText}>
                              El precio aplica para cada cuenta de cobro
                            </span>
                          </Col>
                        </Row>
                        {gContext.isInteractuar ? (
                          <Row className={styles.linkRow}>
                            <Col xs={1}>
                              <span>
                                <img
                                  src={Vector}
                                  alt=""
                                  className={styles.iconImage}
                                />
                              </span>
                            </Col>
                            <Col lg={10} xs={10}>
                              <span className={styles.linkText}>
                                Si el valor de tu cuenta de cobro es menor a
                                $600.000 COP el costo es de COP{"\u00A0"}
                              </span>
                              <span
                                className={`${styles.linkTextGreen} ${
                                  gContext.isInteractuar && styles.textBlue
                                }`}
                              >
                                $20.000{"\u00A0"}
                              </span>
                              <span className={styles.linkText}>+ GMF</span>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <GMFModal
                openModal={openModal}
                onClose={handleGmfModalClose}
              ></GMFModal>
            </Container>
          )
        }}
      />
    </Box>
  )
}
