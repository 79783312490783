import React from "react"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import Clients from "../sections/landing1/Clients"
import Contact from "../sections/landing1/Contact"
import Description from "../sections/landing1/Description"
import Hero from "../sections/landing1/Hero"
import HowItWorks from "../sections/landing1/HowItWorks"
import Pricing from "../sections/landing1/Pricing"

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Seo />
        <Hero />
        <HowItWorks />
        <Description />
        <Pricing />
        <Contact />
        <Clients />
      </PageWrapper>
    </>
  )
}
export default IndexPage
