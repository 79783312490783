import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Box, Button, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"
import KeyGif from "../../images/gif/key.gif"
import ChecklistGif from "../../images/gif/list.gif"
import PiggyGif from "../../images/gif/piggy.gif"
import ClockGif from "../../images/gif/time.gif"
import * as styles from "./howitworks.module.css"

export default () => {
  const [activeStep, setActiveStep] = useState(1)
  const gContext = useContext(GlobalContext)

  const handleStepClic = clickedStep => {
    setActiveStep(clickedStep)
  }

  return (
    <Box id="howitworks">
      <StaticQuery
        query={graphql`
          query {
            howItWorksStep1: file(
              relativePath: { eq: "howitworks/how_it_works_1.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksStep2: file(
              relativePath: { eq: "howitworks/how_it_works_2.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksStep3: file(
              relativePath: { eq: "howitworks/how_it_works_3.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksStep4: file(
              relativePath: { eq: "howitworks/how_it_works_4.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep1: file(
              relativePath: { eq: "howitworks/step_1_brackground_figure.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep2: file(
              relativePath: { eq: "howitworks/step_2_brackground_figure.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep3: file(
              relativePath: { eq: "howitworks/step_3_brackground_figure.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep4: file(
              relativePath: { eq: "howitworks/step_4_brackground_figure.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            backgroundMobileForm: file(
              relativePath: { eq: "howitworks/brackground_figure_mobile.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep1Blue: file(
              relativePath: {
                eq: "howitworks/step_1_brackground_figure_blue.png"
              }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep2Blue: file(
              relativePath: {
                eq: "howitworks/step_2_brackground_figure_blue.png"
              }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep3Blue: file(
              relativePath: {
                eq: "howitworks/step_3_brackground_figure_blue.png"
              }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            howItWorksBackgroundStep4Blue: file(
              relativePath: {
                eq: "howitworks/step_4_brackground_figure_blue.png"
              }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
            backgroundMobileFormBlue: file(
              relativePath: {
                eq: "howitworks/brackground_figure_mobile_blue.png"
              }
            ) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  quality: 90
                  blurredOptions: { width: 50 }
                )
              }
            }
          }
        `}
        render={data => {
          return (
            <React.Fragment>
              <Container
                css={{ paddingBottom: "150px" }}
                className="d-lg-none d-block justify-content-center"
              >
                <Row>
                  <Col lg={12} className={styles.sectionTitleWrapper}>
                    <span
                      className={styles.sectionTitle}
                      css="font-size:30px !important;"
                    >
                      Cómo funciona
                    </span>
                    <span
                      className={`${styles.sectionTitleDot} ${
                        gContext.isInteractuar && styles.blueColorText
                      }`}
                      css="font-size:30px !important;"
                    >
                      .
                    </span>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      01
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMdGreen} ${
                        gContext.isInteractuar && styles.blueColorText
                      }`}
                    >
                      .
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      {"\u00A0"}
                      {"\u00A0"}Regístrate
                    </span>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span className={styles.sectionSubtitleNormal}>
                      Ingresa tus datos en nuestra plataforma.
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 0px; justify-content: center; "
                  >
                    <GatsbyImage
                      alt=""
                      className={styles.stepImageMd}
                      image={getImage(data.howItWorksStep1)}
                    />
                    <Box className={styles.iconBoxLeftMd}>
                      <img
                        src={KeyGif}
                        alt="keygif"
                        className={styles.iconImage}
                      />
                    </Box>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 0px; justify-content: center; "
                  >
                    <Row css="display:flex !important; justify-content:center !important;">
                      <Link
                        to={`${process.env.GATSBY_AIO_APP_URL}/signup${
                          gContext.isInteractuar
                            ? "?is_from_interactuar=true"
                            : ""
                        }`}
                      >
                        <Button mt={3} mb={2} variant="factcilSolid">
                          Comenzar
                        </Button>
                      </Link>
                    </Row>
                    <Box className={styles.backgroundBoxMobile}>
                      <GatsbyImage
                        alt=""
                        css="max-width: 80%"
                        image={
                          gContext.isInteractuar
                            ? getImage(data.howItWorksBackgroundStep1Blue)
                            : getImage(data.howItWorksBackgroundStep1)
                        }
                      />
                    </Box>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      02
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMdGreen} ${
                        gContext.isInteractuar && styles.blueColorText
                      }`}
                    >
                      .
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      {"\u00A0"}
                      {"\u00A0"}Crea tu solicitud
                    </span>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span className={styles.sectionSubtitleNormal}>
                      Ingresa los datos de la cuenta de cobro o factura que
                      quieres gestionar por medio de Factcil.
                      <br />
                      <br />
                      Selecciona si necesitas el anticipo o no.
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 0px; justify-content: center; "
                  >
                    <Box className={styles.iconBoxRightMd}>
                      <img
                        src={ChecklistGif}
                        alt="checkgif"
                        className={styles.iconImage}
                      />
                    </Box>
                    <GatsbyImage
                      alt=""
                      className={styles.stepImageMd}
                      image={getImage(data.howItWorksStep2)}
                    />
                    <Box className={styles.leftBackgroundBoxMobile}>
                      <GatsbyImage
                        css="max-width: 80%"
                        alt=""
                        image={
                          gContext.isInteractuar
                            ? getImage(data.backgroundMobileFormBlue)
                            : getImage(data.backgroundMobileForm)
                        }
                      />
                    </Box>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      03
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMdGreen} ${
                        gContext.isInteractuar && styles.blueColorText
                      }`}
                    >
                      .
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      {"\u00A0"}
                      {"\u00A0"}Hacemos todo
                    </span>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span className={styles.sectionSubtitleNormal}>
                      &bull;Factcil se registra como proveedor.
                      <br />
                      <br />
                      &bull;Facturamos en representación tuya (No debes hacer
                      más cuentas de cobro)
                      <br />
                      <br />
                      &bull;Liquidamos tu seguridad social y puedes pagar en
                      nuestra plataforma.
                      <br />
                      <br />
                      &bull;Nos encargamos de llamar a cobrar y estar pendientes
                      de tu pago.
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 0px; justify-content: center; "
                  >
                    <Box className={styles.iconBoxLeftMd}>
                      <img src={ClockGif} alt="" className={styles.iconImage} />
                    </Box>
                    <GatsbyImage
                      alt=""
                      className={styles.stepImageMd}
                      image={getImage(data.howItWorksStep3)}
                    />
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      04
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMdGreen} ${
                        gContext.isInteractuar && styles.blueColorText
                      }`}
                    >
                      .
                    </span>
                    <span
                      className={`${styles.sectionSubtitleMd} ${
                        gContext.isInteractuar && styles.sectionSubtitleMdBlue
                      }`}
                    >
                      {"\u00A0"}
                      {"\u00A0"}Recibe tu pago
                    </span>
                  </Col>
                  <Col xs={12} css="text-align: center; margin-top:20px;">
                    <span className={styles.sectionSubtitleNormal}>
                      Si tu servicio <b>no tenía anticipo</b>, recibe tu dinero
                      cuando la empresa que te contrató realice el pago.
                      <br />
                      <br />
                      <b>Si solicitaste anticipo</b> debes esperar máximo 48
                      horas para la aprobación y el desembolso.
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 30px; justify-content: center; "
                  >
                    <Box className={styles.iconBoxLeftMd}>
                      <img src={PiggyGif} alt="" className={styles.iconImage} />
                    </Box>
                    <GatsbyImage
                      alt=""
                      className={styles.stepImageMd}
                      image={getImage(data.howItWorksStep4)}
                    />

                    <Box className={styles.lastStepBackgroundBoxMobile}>
                      <GatsbyImage
                        alt=""
                        image={
                          gContext.isInteractuar
                            ? getImage(data.howItWorksBackgroundStep4Blue)
                            : getImage(data.howItWorksBackgroundStep4)
                        }
                      />
                    </Box>
                  </Col>
                  <Col
                    xs={12}
                    css="text-align: center; margin-top: 0px; justify-content: center; "
                  >
                    <Row css="display:flex !important; justify-content:center !important;">
                      <Link
                        to={`${process.env.GATSBY_AIO_APP_URL}/signup${
                          gContext.isInteractuar
                            ? "?is_from_interactuar=true"
                            : ""
                        }`}
                        css="text-align: center !important; margin-top: 0px !important; justify-content: center !important;"
                      >
                        <Button mt={3} mb={2} variant="factcilSolid">
                          Comenzar
                        </Button>
                      </Link>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Container
                css={{ paddingBottom: "150px" }}
                className="d-none d-lg-block"
              >
                <Row>
                  <Col lg={12} className={styles.sectionTitleWrapper}>
                    <Text
                      fontSize={[
                        "1.8rem",
                        "1.9rem",
                        "2.0rem",
                        "2.1rem",
                        "2.5rem",
                      ]}
                      textAlign={"center"}
                      color="primary"
                      opacity={1}
                      fontWeight={"bold"}
                      lineHeight={["50px"]}
                    >
                      Cómo funciona
                      <span
                        className={`${styles.sectionTitleDot} ${
                          gContext.isInteractuar && styles.blueColorText
                        }`}
                      >
                        .
                      </span>
                    </Text>
                  </Col>
                </Row>
                <Row className={styles.sectionSubtitleRow}>
                  <Col md={3} className="">
                    <Col xs={12} className={styles.sectionSubtitleContainer}>
                      <span
                        className={`${styles.sectionSubtitle} ${
                          activeStep === 1 && styles.sectionSubtitleActive
                        }`}
                        onClick={() => handleStepClic(1)}
                      >
                        01.{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}Regístrate
                      </span>
                    </Col>
                    <Col xs={12} className={styles.sectionSubtitleContainer}>
                      <span
                        className={`${styles.sectionSubtitle} ${
                          activeStep === 2 && styles.sectionSubtitleActive
                        }`}
                        onClick={() => handleStepClic(2)}
                      >
                        02.{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}Crea tu solicitud
                      </span>
                    </Col>
                    <Col xs={12} className={styles.sectionSubtitleContainer}>
                      <span
                        className={`${styles.sectionSubtitle} ${
                          activeStep === 3 && styles.sectionSubtitleActive
                        }`}
                        onClick={() => handleStepClic(3)}
                      >
                        03.{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}Hacemos todo
                      </span>
                    </Col>
                    <Col xs={12} className={styles.sectionSubtitleContainer}>
                      <span
                        className={`${styles.sectionSubtitle} ${
                          activeStep === 4 && styles.sectionSubtitleActive
                        }`}
                        onClick={() => handleStepClic(4)}
                      >
                        04.{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}Recibe tu pago
                      </span>
                    </Col>
                  </Col>
                  {activeStep === 1 && (
                    <React.Fragment>
                      <Col
                        lg={6}
                        md={5}
                        css="text-align: center;"
                        className={`${styles.imageWrapper} ${
                          gContext.isInteractuar && styles.imageWrapperBlue
                        }`}
                      >
                        <Box className={styles.topIconBox}>
                          <img
                            src={KeyGif}
                            alt=""
                            className={styles.iconImage}
                          />
                        </Box>
                        <Box className={styles.backgroundBox}>
                          <GatsbyImage
                            alt=""
                            css="max-width: 80%"
                            image={
                              gContext.isInteractuar
                                ? getImage(data.howItWorksBackgroundStep1Blue)
                                : getImage(data.howItWorksBackgroundStep1)
                            }
                          />
                        </Box>
                        <GatsbyImage
                          alt=""
                          className={styles.stepImage}
                          image={getImage(data.howItWorksStep1)}
                        />
                        <a
                          href={`${process.env.GATSBY_AIO_APP_URL}/signup${
                            gContext.isInteractuar
                              ? "?is_from_interactuar=true"
                              : ""
                          }`}
                        >
                          <Box className={styles.imageBox}>
                            <Row>
                              <Col xs={12} css={{ maxHeight: "12px" }}>
                                <span className={styles.factcilUrl}>
                                  factcil.com/signup
                                </span>
                              </Col>
                            </Row>
                          </Box>
                        </a>
                      </Col>
                      <Col lg={2} md={3} className={styles.textWrapper}>
                        <Col md={12}>
                          <span className={styles.imageDescriptionText}>
                            Ingresa tus datos en nuestra plataforma.
                          </span>
                        </Col>
                        <Col md={12}>
                          <Link
                            to={`${process.env.GATSBY_AIO_APP_URL}/signup${
                              gContext.isInteractuar
                                ? "?is_from_interactuar=true"
                                : ""
                            }`}
                          >
                            <Button mt={3} mb={2} variant="factcilSolid">
                              Comenzar
                            </Button>
                          </Link>
                        </Col>
                      </Col>
                    </React.Fragment>
                  )}
                  {activeStep === 2 && (
                    <React.Fragment>
                      <Col
                        lg={6}
                        md={5}
                        className={`${styles.imageWrapper} ${
                          gContext.isInteractuar && styles.imageWrapperBlue
                        }`}
                        css="text-align: center;"
                      >
                        <Box className={styles.backgroundBox}>
                          <GatsbyImage
                            css="max-width: 80%"
                            alt=""
                            image={
                              gContext.isInteractuar
                                ? getImage(data.howItWorksBackgroundStep2Blue)
                                : getImage(data.howItWorksBackgroundStep2)
                            }
                          />
                        </Box>
                        <Box className={styles.leftIconBox}>
                          <img
                            src={ChecklistGif}
                            alt=""
                            className={styles.iconImage}
                          />
                        </Box>
                        <GatsbyImage
                          alt=""
                          className={styles.stepImage}
                          image={getImage(data.howItWorksStep2)}
                        />
                      </Col>
                      <Col lg={2} md={3} className={styles.textWrapper}>
                        <Col md={12}>
                          <span className={styles.imageDescriptionText}>
                            Ingresa los datos de la cuenta de cobro o factura
                            que quieres gestionar por medio de Factcil.
                            <br />
                            <br />
                            Selecciona si necesitas el anticipo o no.
                          </span>
                        </Col>
                      </Col>
                    </React.Fragment>
                  )}
                  {activeStep === 3 && (
                    <React.Fragment>
                      <Col
                        lg={6}
                        md={5}
                        className={`${styles.imageWrapper} ${
                          gContext.isInteractuar && styles.imageWrapperBlue
                        }`}
                        css="text-align: center;"
                      >
                        <Box className={styles.topIconBox}>
                          <img
                            src={ClockGif}
                            alt=""
                            className={styles.iconImage}
                          />
                        </Box>
                        <Box className={styles.backgroundBox}>
                          <GatsbyImage
                            alt=""
                            css="max-width: 80%"
                            image={
                              gContext.isInteractuar
                                ? getImage(data.howItWorksBackgroundStep3Blue)
                                : getImage(data.howItWorksBackgroundStep3)
                            }
                          />
                        </Box>
                        <Box className={styles.stepThreeBox}>
                          <Row>
                            <Col xs={12} css={{ maxHeight: "12px" }}>
                              <span className={styles.stepThreeBoxText}>
                                Recibe respuesta en máximo 2 días hábiles.
                              </span>
                            </Col>
                          </Row>
                        </Box>
                        <GatsbyImage
                          alt=""
                          className={styles.stepImage}
                          image={getImage(data.howItWorksStep3)}
                        />
                      </Col>
                      <Col lg={2} md={3} className={styles.textWrapper}>
                        <Col md={12}>
                          <ul>
                            <li className={styles.listItem}>
                              Factcil se registra como proveedor.
                            </li>
                            <br />
                            <li className={styles.listItem}>
                              Facturamos en representación tuya (No debes hacer
                              más cuentas de cobro).
                            </li>
                            <br />
                            <li className={styles.listItem}>
                              Liquidamos tu seguridad social y puedes pagar en
                              nuestra plataforma.
                            </li>
                            <br />
                            <li className={styles.listItem}>
                              Nos encargamos de llamar a cobrar y estar
                              pendientes de tu pago.
                            </li>
                          </ul>
                        </Col>
                      </Col>
                    </React.Fragment>
                  )}
                  {activeStep === 4 && (
                    <React.Fragment>
                      <Col
                        lg={6}
                        md={5}
                        className={`${styles.imageWrapper} ${
                          gContext.isInteractuar && styles.imageWrapperBlue
                        }`}
                        css="text-align: center; height: 377px; display: flex;"
                      >
                        <Box className={styles.lastStepBackgroundBox}>
                          <GatsbyImage
                            alt=""
                            image={
                              gContext.isInteractuar
                                ? getImage(data.howItWorksBackgroundStep4Blue)
                                : getImage(data.howItWorksBackgroundStep4)
                            }
                          />
                        </Box>
                        <Box className={styles.piggyIconBox}>
                          <img
                            src={PiggyGif}
                            alt=""
                            className={styles.iconImage}
                          />
                        </Box>
                        <GatsbyImage
                          alt=""
                          className={styles.stepImageLastStep}
                          image={getImage(data.howItWorksStep4)}
                        />
                      </Col>
                      <Col lg={2} md={3} className={styles.textWrapper}>
                        <Col md={12}>
                          <span className={styles.imageDescriptionText}>
                            Si tu servicio <b>no tenía anticipo</b>, recibe tu
                            dinero cuando la empresa que te contrató realice el
                            pago.
                            <br />
                            <br />
                            <b>Si solicitaste anticipo</b> debes esperar máximo
                            48 horas para la aprobación y el desembolso.
                          </span>
                        </Col>
                        <Col md={12}>
                          <Link
                            to={`${process.env.GATSBY_AIO_APP_URL}/signup${
                              gContext.isInteractuar
                                ? "?is_from_interactuar=true"
                                : ""
                            }`}
                          >
                            <Button mt={3} mb={2} variant="factcilSolid">
                              Comenzar
                            </Button>
                          </Link>
                        </Col>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Container>
            </React.Fragment>
          )
        }}
      />
    </Box>
  )
}
