// extracted by mini-css-extract-plugin
export var boldText = "pricing-module--bold-text--9PxW1";
export var budget = "pricing-module--budget--vsXYA";
export var iconImage = "pricing-module--icon-image--uIODG";
export var image = "pricing-module--image--wc14a";
export var imageBox = "pricing-module--image-box--DPfXU";
export var imageForm = "pricing-module--image-form--J0TpJ";
export var imageFormMobile = "pricing-module--image-form-mobile--JOPiA";
export var imagePrice = "pricing-module--image-price--wsr3E";
export var imagePriceLineTrough = "pricing-module--image-price-line-trough--maYAo";
export var imagePriceSmall = "pricing-module--image-price-small--OoGCe";
export var imageWrapper = "pricing-module--image-wrapper--ptTI6";
export var linkRow = "pricing-module--link-row--3chqR";
export var linkText = "pricing-module--link-text---o1wW";
export var linkTextGreen = "pricing-module--link-text-green--2rjhf";
export var linkTextWithAction = "pricing-module--link-text-with-action--DceL3";
export var linkWrapper = "pricing-module--link-wrapper---H10H";
export var modalTable = "pricing-module--modal-table--Xo6xk";
export var normalText = "pricing-module--normal-text--mB-Mm";
export var sectionSubtitle = "pricing-module--section-subtitle--CoGgl";
export var sectionSubtitleRow = "pricing-module--section-subtitle-row--D2lBk";
export var sectionTitle = "pricing-module--section-title--+dmKH";
export var sectionTitleDot = "pricing-module--section-title-dot--kX2t3";
export var sectionTitleWrapper = "pricing-module--section-title-wrapper--P9vw5";
export var textBlue = "pricing-module--text-blue--yQ9Yb";