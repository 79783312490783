import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useMemo } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Section, Span, Text, Title } from "../../components/Core"
import { FactcilSolidButton } from "../../components/Core/Button"
import GlobalContext from "../../context/GlobalContext"
import ScrollIcon from "../../images/svg/icons/scroll.svg"

const SectionStyled = styled(Section)`
  padding-top: 0px;
  background: linear-gradient(
    180deg,
    #e7f8ff 0%,
    rgba(231, 248, 255, 0.05) 100%
  );
`

const query = graphql`
  query {
    LeftForm: file(relativePath: { eq: "landing-banner-left-form.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 90 }
        )
      }
    }
    BannerPersonDesktop: file(
      relativePath: { eq: "landing-2-banner-persons.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 95 }
        )
      }
    }

    BannerPersonDesktopInteractuar: file(
      relativePath: { eq: "landing-2-banner-persons-interactuar.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 95 }
        )
      }
    }

    BannerPersonMobile: file(
      relativePath: { eq: "landing-2-banner-persons-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }

    BannerPersonMobileInteractuar: file(
      relativePath: { eq: "landing-2-banner-persons-interactuar-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
  }
`

const HighlightedText = styled(Span)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
`

const Button = styled(FactcilSolidButton)`
  width: auto !important;
`

const ScrollDiv = styled.div`
  margin-top: -1.5rem;
  @media (max-width: 992px) {
    margin-top: 1.5rem;
  }

  img {
    height: 2rem;
    margin-right: 0.5rem;
  }
`

const SpanStyled = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 20px;
  color: #717584;
`

const Hero = () => {
  const gContext = useContext(GlobalContext)

  return (
    <StaticQuery
      query={query}
      render={data => {
        const bannerImageProps = {
          alt: "banner-main-image",
          "data-aos": "fade-left",
          "data-aos-duration": "750",
          "data-aos-delay": "500",
          "data-aos-once": "true",
        }

        const leftFormBox = useMemo(() => {
          return (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                height: "100%",
                width: "100%",
              }}
              className="d-none d-md-block"
            >
              <Row className="h-100 d-flex align-content-end flex-wrap">
                <Col
                  md="3"
                  style={{
                    maxWidth: "320px",
                  }}
                >
                  <GatsbyImage
                    image={getImage(data.LeftForm)}
                    alt={"left-form-image"}
                  />
                </Col>
              </Row>
            </div>
          )
        }, [])

        const bannerRightCol = useMemo(() => {
          return (
            <Col md="6" className="order-md-2">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box>
                  {/* md and up image */}
                  <div className="d-none d-md-block">
                    <GatsbyImage
                      image={getImage(
                        gContext.isInteractuar
                          ? data.BannerPersonDesktopInteractuar
                          : data.BannerPersonDesktop
                      )}
                      {...bannerImageProps}
                    />
                  </div>
                  {/* mobile image */}
                  <div className="d-block d-md-none">
                    <GatsbyImage
                      image={getImage(
                        gContext.isInteractuar
                          ? data.BannerPersonMobileInteractuar
                          : data.BannerPersonMobile
                      )}
                      {...bannerImageProps}
                    />
                  </div>
                </Box>
              </div>
            </Col>
          )
        }, [gContext])

        const bannerLeftDescription = useMemo(() => {
          return (
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <Box
                py={[null, null, null, 5]}
                pr={[null, null, null, 40]}
                pt={[null, null]}
                mt={[0, 0, 120, 160]}
              >
                <Title
                  variant="banner"
                  color="primary"
                  fontSize={["16px", "18px", "21px"]}
                >
                  {gContext.isInteractuar ? (
                    "Somos la primer plataforma diseñada para trabajadores independientes."
                  ) : (
                    <>
                      {"Todo el proceso freelance, en un solo lugar"}
                      <HighlightedText>. </HighlightedText>
                    </>
                  )}
                </Title>
                <Text
                  mb={[3, 2, 3, 4]}
                  fontSize={[
                    "1.1rem",
                    "1.15rem",
                    "1.18rem",
                    "1.20rem",
                    "1.25rem",
                  ]}
                  color="primary"
                  opacity={0.9}
                  fontWeight={"normal"}
                  lineHeight={["22px", "30px"]}
                >
                  {gContext.isInteractuar ? (
                    "Nos encargamos de todo: Registro de proveedor, facturación, cobranza, liquidación y pago de seguridad social. También te adelantamos el dinero cuando lo requieras."
                  ) : (
                    <>
                      {
                        "Si eres creador de contenido, médico, fotógrafo, trabajas por prestación de servicios o como freelancer, Factcil te asiste para que te dediques a tu trabajo y no al papeleo y los trámites."
                      }
                      <br />
                      <br />
                      {"Organiza tu operación y anticipa tus pagos."}
                    </>
                  )}
                </Text>
                <div className="d-flex flex-column align-items-start">
                  <a
                    href={`${process.env.GATSBY_AIO_APP_URL}/signup${gContext.isInteractuar ? "?is_from_interactuar=true" : ""
                      }`}
                    rel="noopener noreferrer"
                  >
                    <Button variant="solid" color="light" bg="primary">
                      Quiero ser parte
                    </Button>
                  </a>
                </div>
              </Box>
            </div>
          )
        }, [gContext])

        return (
          <>
            {/* <!-- Hero Area --> */}
            <SectionStyled className="position-relative">
              {/* left form */}

              {leftFormBox}

              <Container>
                <Row className="justify-content-start position-relative">
                  {bannerRightCol}

                  <Col md="6" className="order-md-1">
                    {bannerLeftDescription}
                    <ScrollDiv>
                      <img src={ScrollIcon} alt="scroll-icon" />
                      <SpanStyled>¡Haz scroll y entérate de más!</SpanStyled>
                    </ScrollDiv>
                  </Col>
                </Row>
              </Container>
            </SectionStyled>
          </>
        )
      }}
    />
  )
}

export default Hero
