import React from "react"
import { Table } from "react-bootstrap"
import InfoModal from "../Modals/InfoModal"
import * as styles from "./styles.module.css"

const GMFModal = props => {
  return (
    <InfoModal
      {...props}
      openModal={props.openModal}
      onClose={props.onClose}
      titleText="GMF: gravamen a los movimientos financieros"
      textContent={
        <React.Fragment>
          <span
            css={
              "width: 480px; height: 100px; left: 0px; top: 80px;  font-style: normal; font-weight: normal; font-size: 16px; line-height: 20px; letter-spacing: 0.02em; color: #002432;"
            }
          >
            Es el impuesto que se aplica a las transacciones financieras
            (4x1000). Se calcula sobre el valor que vas a cobrar menos (-)
            retenciones.
            <br />
            <br />
            Así:
          </span>
          <Table className={styles.modalTable}>
            <tbody>
              <tr>
                <td>Cuenta de cobro</td>
                <td>$1,000,000</td>
              </tr>
              <tr>
                <td>Total retenciones</td>
                <td>$110,000</td>
              </tr>
              <tr>
                <td>GMF</td>
                <td>$3,560*</td>
              </tr>
              <tr>
                <td>Total a recibir</td>
                <td>$886,440</td>
              </tr>
            </tbody>
          </Table>
          <span
            css={
              "width: 480px; height: 100px; left: 0px; top: 80px;  font-style: normal; font-weight: normal; font-size: 0.75rem; line-height: 20px; letter-spacing: 0.02em; color: #002432;"
            }
          >
            * Valor GMF calculado así: $890.000 * 0.4%
          </span>
        </React.Fragment>
      }
    ></InfoModal>
  )
}

export default GMFModal
