import { Link } from "gatsby"
import React, { useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Span, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"

const StyledBox = styled(Box)`
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 2rem;

  &:before {
    content: "";
    width: 200px !important;
    height: 200px !important;
    right: 0;
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(50px);
    -webkit-filter: blur(50px) !important;
    -moz-filter: blur(50px) !important;
    z-index: -1;
    background-color: #dbf2fc;
    background-size: 10%;
  }
`

const HighlightedTextInGreen = styled(Span)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
`

const HighlightedTextInBlue = styled(Span)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.navyBlue};
`

const Description = () => {
  const gContext = useContext(GlobalContext)

  return (
    <Container id="description">
      <Row>
        <Col style={{ justifyContent: "center" }}>
          <StyledBox>
            <Text
              fontSize={["1.5rem", "1.5rem", "1.7rem", "1.8rem", "2.2rem"]}
              color="primary"
              className={"text-center m-0 p-0"}
              opacity={0.9}
              fontWeight={"600"}
              lineHeight={["35px", "35px", "35px", "45px", "50px"]}
            >
              {gContext.isInteractuar ? (
                <>
                  {"Nos encargamos de"}
                  <HighlightedTextInBlue> todo </HighlightedTextInBlue>
                  {"el proceso que debes hacer para trabajar con cualquier empresa que te contrate, desde el registro de proveedor, liquidación de seguridad social, facturación y gestión de cobranza. Y si no te pagan inmediato, puedes solicitar el"}
                  <HighlightedTextInBlue> anticipo</HighlightedTextInBlue>
                  {"."}
                </>
              ) : (
                <>
                  {"Creamos una plataforma para que puedas llevar el día a día de tu operación de una manera"}
                  <HighlightedTextInGreen> más fácil</HighlightedTextInGreen>
                  {"; desde llevar tus ingresos, recordar quién te debe, controlar tus gastos y anticipar el pago de tus cuentas de cobro cuando tus clientes no te pagan de inmediato."}
                </>
              )}
            </Text>
            <Row xs={12}>
              <Box className="p-0 m-0 pt-5 d-flex justify-content-center align-items-center">
                <Link
                  to={`${process.env.GATSBY_AIO_APP_URL
                    }/signup${gContext.isInteractuar ? "?is_from_interactuar=true" : ""
                    }`}
                  className="m-0 py-1 px-4 link d-flex direction-row justify-content-center align-items-center text-decoration-none border"
                  css="border-radius: 2.5rem"
                >
                  <Text
                    fontWeight="bold"
                    textDecoration="none"
                    fontSize="0.875rem"
                    pr={3}
                  >
                    Comenzar ahora
                  </Text>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.129 5.22859L5.106 1.09077L6.1665 -2.69546e-07L12 6L6.1665 12L5.106 10.9092L9.129 6.77141L-2.28549e-07 6.77141L-2.95988e-07 5.22859L9.129 5.22859Z"
                      fill="#002432"
                    />
                  </svg>
                </Link>
              </Box>
            </Row>
          </StyledBox>
        </Col>
      </Row>
    </Container>
  )
}

export default Description
