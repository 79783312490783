import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Box } from "../../components/Core"
import { device } from "../../utils"

const FintechImage = styled(GatsbyImage)`
  opacity: 0.7;
  @media ${device.xs} {
    height: 4rem;
    margin: 1rem 0;
  }
  @media ${device.md} {
    height: 4rem;
    margin: 0 2rem;
  }
`
const RockstartImage = styled(GatsbyImage)`
  opacity: 0.7;
  @media ${device.xs} {
    height: 2.5rem;
    margin: 1rem 0;
  }
  @media ${device.md} {
    height: 2.5rem;
    width: 13rem;
    margin: 0 2rem;
  }
`
const VillageImage = styled(GatsbyImage)`
  opacity: 0.7;
  @media ${device.xs} {
    height: 2.5rem;
    margin: 1rem 0;
  }
  @media ${device.md} {
    height: 2.5rem;
    width: 15rem;
    margin: 0 2rem;
  }
`

const query = graphql`
  query {
    ColombiaFintech: file(relativePath: { eq: "colombia-fintech.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    Rockstart: file(relativePath: { eq: "rockstart.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    VillageCapital: file(relativePath: { eq: "village-capital.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
  }
`

const ImageQueryRow = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Box className="p-0 m-0 d-flex flex-column flex-md-row align-items-md-end justify-content-center">
        <FintechImage
          image={getImage(data.ColombiaFintech)}
          alt="colombia-fintech"
          loading={"eager"}
          objectFit="contain"
        />
        <RockstartImage
          image={getImage(data.Rockstart)}
          alt="rockstart"
          loading={"eager"}
          objectFit="contain"
        />
        <VillageImage
          image={getImage(data.VillageCapital)}
          alt="village-capital"
          loading={"eager"}
          objectFit="contain"
        />
      </Box>
    )}
  />
)

const BoxStyled = styled(Box)`
  padding: 0;
  padding-bottom: 5rem;

  @media (min-width: 992px) and (max-width: 1200px) {
    margin-top: -5rem !important;
  }

  @media (min-width: 1201px) {
    position: absolute;
    left: 18%;
    transform: translate(0, -120%);
  }

  @media (min-width: 1400px) {
    position: absolute;
    left: 24%;
    transform: translate(0, -130%);
  }
`

const Clients = () => {
  const firstImageBlock = () => {
    return (
      <div id="first-partner-block">
        <ImageQueryRow />
      </div>
    )
  }

  return (
    <>
      {/* <!-- Clients Brands Area --> */}
      <BoxStyled>
        <Box className="container text-center">{firstImageBlock()}</Box>
      </BoxStyled>
    </>
  )
}

export default Clients
