import PropTypes from 'prop-types';
import React from 'react';
import styled from "styled-components";
import { hexToRgb } from '../utils/helperFn';

function Label({ className, color, children, ...rest }) {

  const StyledSpan = styled.span`
    align-items: 'center';
    border-radius: 4px;
    display: 'inline-flex';
    flex-grow: 0;
    white-space: 'nowrap';
    cursor: 'default';
    flex-shrink: 0;
    font-size: 0.8rem;
    font-weight: 5px;
    height: 20px;
    justify-content: 'center';
    letter-spacing: 0.5px;
    min-width: 20px;
    padding: 0.2rem;
    color: ${({ theme }) => theme.colors[color]} !important;
    background-color: rgba(${({ theme }) => {
      const rgb = hexToRgb(theme.colors[color])
      return `${rgb.r},${rgb.g},${rgb.b}`
    }}, 0.2)  !important;
    margin-left: 10px;
  `


  return (
    <StyledSpan
      className={className}
      {...rest}
    >
      {children}
    </StyledSpan>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success'
  ])
};

Label.defaultProps = {
  className: '',
  color: 'secondary'
};

export default Label;
