import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Button, List, Text, Title } from "../../components/Core"
import InfoModal from "../../components/Modals/InfoModal"
import GlobalContext from "../../context/GlobalContext"
import BlueElephant from "../../images/png/blue-factcil-elephant.png"
import Interactuar from "../../images/png/interactuar.png"
import { device } from "../../utils"

const TitleText = styled(Title)`
  padding: 0;
  margin: 0;
  p {
    color: ${({ theme }) => theme.colors.dark};
    font-style: normal;
    font-weight: 700;

    span {
      color: ${({ theme }) => theme.colors.green};

      &.color-blue {
        color: ${({ theme }) => theme.colors.navyBlue};
      }
    }
  }
`

const SubtitleText = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.dark};
  @media ${device.xs} {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  @media ${device.md} {
    font-size: 1.15rem;
    line-height: 1.875rem;
  }
  @media ${device.lg} {
    font-size: 1.1rem;
    line-height: 1.875rem;
  }

  @media ${device.xl} {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`

const ListDecorator = styled(List)`
  list-style: none;
  li::before {
    background-color: ${({ theme, gContext }) => gContext.isInteractuar ? theme.colors.lightBlue : theme.colors.lightGreen};
  }
`

const ListItem = styled.li`
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.dark};
    b {
      font-weight: 700;
    }
    @media ${device.xs} {
      font-size: 1rem;
      line-height: 1.1rem;
    }
    @media ${device.md} {
      font-size: 1.15rem;
      line-height: 1.875rem;
    }
    @media ${device.lg} {
      font-size: 1.1rem;
      line-height: 1.875rem;
    }

    @media ${device.xl} {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
`

const FormBox = styled(Box)`
  @media ${device.md} {
    background: ${({ theme }) => theme.colors.light};
    box-shadow: 0px 0px 2.5rem rgba(0, 36, 50, 0.05);
    border-radius: 1.25rem;
  }
`
const SectionBackDecorator = styled.div`
  width: 15.75rem;
  height: 3rem;

  background: ${({ theme, isInteractuar }) => isInteractuar ? theme.colors.navyBlue : theme.colors.green};

  box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
  border-radius: 18.75rem;

  background: ${({ theme, isInteractuar }) => isInteractuar ? theme.colors.navyBlue : theme.colors.green};
  @media ${device.xs} {
    transform: translate(14rem, -3.5rem);
  }
  @media ${device.sm} {
    transform: translate(20rem, -3.5rem);
  }
  @media ${device.md} {
    display: none;
  }
`

const FormBackDecorator = styled.div`
  position: absolute;
  width: 8.75rem;
  height: 13.75rem;
  border: 1px solid ${({ gContext }) => gContext.isInteractuar ? "#00A9E0" : "#93a141"};
  box-sizing: border-box;
  border-radius: 18.75rem;
  @media ${device.xs} {
    transform: translate(10rem, -12rem);
  }
  @media ${device.sm} {
    transform: translate(19rem, -10rem);
  }
  @media ${device.md} {
    transform: translate(13rem, 8rem);
  }
  @media ${device.lg} {
    transform: translate(13rem, 8rem);
  }
  @media ${device.xl} {
    transform: translate(15rem, 8rem);
  }
  @media (min-width: 1400px) {
    transform: translate(17rem, 8rem);
  }
  @media (min-width: 1600px) {
    transform: translate(20rem, 8rem);
  }
  @media (min-width: 1920px) {
    transform: translate(22rem, 8rem);
  }
`

const FormGreenDecorator = styled.div`
  @media ${device.md} {
    position: absolute;
    width: 4.5rem;
    height: 3rem;

    box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
    border-radius: 18.75rem;

    background: ${({ theme, gContext }) => gContext.isInteractuar ? theme.colors.navyBlue : theme.colors.green};
    transform: translate(-3rem, 2rem);
  }
`
const DescriptionBackDecorator = styled.div`
  position: absolute;
  width: 24rem;
  height: 13.75rem;

  border: 1px solid #ecefd7;
  box-sizing: border-box;
  border-radius: 18.75rem;

  @media ${device.xs} {
    transform: translate(-14rem, -6rem);
  }
  @media ${device.sm} {
    transform: translate(-14rem, -7rem);
  }
  @media ${device.md} {
    transform: translate(-10rem, -1rem);
  }
  @media ${device.lg} {
    transform: translate(-10rem, -1rem);
  }
  @media ${device.xl} {
    transform: translate(-10rem, 0rem);
  }
`

const FormContent = styled(Form)`
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: ${({ theme }) => theme.colors.dark};
  }
  input,
  select {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    border: 0.063 solid #e4f0f4;
    box-sizing: border-box;
    border-radius: 0.25rem;
    height: 3rem;

    &:hover,
    &:focus,
    &.active {
      background: #ffffff;
      border: 1px solid #0080b2;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  button {
    background-color: ${({ theme }) => theme.colors.dark};
    border: none;
    border-radius: 2.5rem;
    width: 12.5rem;
    height: 3rem;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
    transform: none !important;
    transition: all 0.3s ease-in !important;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
  }
  button:hover {
    background-color: ${({ theme }) => theme.colors.hoverBlue};
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: ${({ theme }) => theme.colors.dark};
    a {
      color: ${({ theme }) => theme.colors.dark} !important;
      &:hover {
        text-decoration: underline !important;
        color: ${({ theme }) => theme.colors.footerHighlight} !important;
      }
    }
  }
`

const ElephantContainer = styled(Box)`
  display: none;
  @media ${device.md} {
    display: block;

    img {
      height: 3.75rem;
    }
  }
`
const ModalText = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.dark};
`

const listItems = [
  {
    id: 1,
    text: (
      <>
        Mejorar las relaciones con los <b>proveedores</b>.
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <b>Disminuir</b> los costos financieros y simplificar el pago.
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        Disminuir la carga <b>administrativa.</b>
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        Apalancamiento frente a la <b>Ley de Pronto Pago</b>.
      </>
    ),
  },
]

const optionItems = [
  {
    id: 1,
    label: "Entre 1 y 15",
  },
  {
    id: 2,
    label: "Entre 16 y 100",
  },
  {
    id: 3,
    label: "Entre 101 y 300",
  },
  {
    id: 4,
    label: "Más de 300",
  },
]

const Contact = () => {
  const [isOpenModal, setOpenModal] = useState(false)

  const [form, setForm] = useState({
    allied_employee_number: optionItems[0].label,
  })
  const [modalContent, setModalContent] = useState(["", <></>])
  const [isLoading, setIsLoading] = useState(false)

  const gContext = useContext(GlobalContext)

  const handleOpenModal = () => {
    setOpenModal(!isOpenModal)
  }

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        await axios
          .get(process.env.GATSBY_API_URL + "/landing/landing-token/", {
            withCredentials: false,
          })
          .then(res => {
            setField("csrfmiddlewaretoken", res.data.token)
          })
      } catch (e) {
        // do nothing
      }
    }
    getCsrfToken()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const formData = new FormData()
      Object.keys(form).forEach(key => {
        // @ts-ignore
        formData.append(key, form[key])
      })
      await axios({
        method: "post",
        url: process.env.GATSBY_API_URL + "/landing/landing-new-allied",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        const data = response.data

        if (data.code === 6) {
          setModalContent([
            "Ha ocurrido un error",
            <ModalText>
              Ocurrió un error inesperado de parte del servidor.
            </ModalText>,
          ])
        } else if (data.code === 1) {
          setModalContent([
            "¡Gracias por contactarnos!",
            <ModalText>
              Hemos recibido tus datos y pronto alguien de nuestro equipo se
              pondrá en contacto contigo.
            </ModalText>,
          ])
        } else {
          setModalContent([
            "Ha ocurrido un error",
            <ModalText>
              Ocurrió un error inesperado de parte del servidor, inténtelo
              nuevamente en unos instantes.
            </ModalText>,
          ])
        }
        handleOpenModal()
      })
    } catch (e) {
      setModalContent([
        "Ha ocurrido un error",
        <ModalText>
          Ocurrió un error inesperado de parte del servidor, inténtelo
          nuevamente en unos momentos.
        </ModalText>,
      ])

      handleOpenModal()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row className="d-flex justify-content-center mx-0 px-0" id="partners">
      <SectionBackDecorator isInteractuar={gContext.isInteractuar} />

      <Col xs={12} xl={9} className="d-md-flex flex-md-row">
        {gContext.isInteractuar ? "" : <DescriptionBackDecorator />}

        <Col
          xs={12}
          sm={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Col xs={12} md={11} lg={10} xl={11} className="pl-xl-5">
            <Row>
              <TitleText>
                <Text
                  fontSize={["1.8rem", "1.9rem", "2.0rem", "2.1rem", "2.5rem"]}
                  color="primary"
                  opacity={1}
                  fontWeight={"600"}
                  lineHeight={["35px", "35px", "35px", "40px", "50px"]}
                >
                  Si eres <br />
                  una empresa<span className={`${gContext.isInteractuar ? "color-blue" : ""}`}>,</span>
                </Text>
                <Text></Text>
              </TitleText>
            </Row>
            <Row>
              <SubtitleText className="py-3 py-md-5">
                y contratas trabajadores independientes, déjanos tus datos, te
                ofrecemos:
              </SubtitleText>
            </Row>
            <Row>
              <ListDecorator gContext={gContext}>
                {listItems.map(item => (
                  <ListItem key={item.id} className="px-0 mx-0">
                    <Text>{item.text}</Text>
                  </ListItem>
                ))}
              </ListDecorator>
            </Row>
          </Col>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center px-0 mx-0"
        >
          <FormBackDecorator gContext={gContext} />
          <Col xs={12} sm={12} md={11} lg={9} xl={11} className="px-0 mx-0">
            <FormGreenDecorator gContext={gContext} />
            <FormBox className="container-fluid px-0 mx-0">
              <Row className="container-fluid mx-0 pt-md-5 pb-md-4">
                <ElephantContainer className="container-fluid text-center">
                  {gContext.isInteractuar ? <img src={Interactuar} alt="blue-elephant" /> : <img src={BlueElephant} alt="blue-elephant" />}
                </ElephantContainer>
              </Row>

              <Row className="container-fluid mx-0 px-0 px-sm-0 px-md-4 px-lg-4 px-xl-5 ">
                <FormContent
                  className="container-fluid"
                  onSubmit={handleSubmit}
                >
                  <Form.Group controlId="formName" role="form">
                    <Form.Label>Nombre de tu empresa</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre de tu empresa"
                      onChange={e => setField("allied_name", e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formCompany">
                    <Form.Label>Teléfono de contacto</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Teléfono de contacto"
                      onChange={e => setField("allied_phone", e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Correo electrónico</Form.Label>

                    <Form.Control
                      type="email"
                      placeholder="Correo electrónico"
                      onChange={e => setField("allied_mail", e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="exampleForm.ControlSelect1"
                    className="my-0"
                  >
                    <Form.Label>Cantidad de empleados</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e =>
                        setField("allied_employee_number", e.target.value)
                      }
                    >
                      {optionItems.map(option => (
                        <option key={option.id}>{option.label}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Text className="text-center py-4">
                    Al hacer clic en enviar estás aceptando nuestra
                    <br />
                    <a href="/privacy-policy">
                      política de tratamiento de datos
                    </a>
                    .
                  </Text>
                  <Row className="container-fluid justify-content-center px-0 mx-0 pb-5">
                    <Button
                      disabled={isLoading}
                      type="submit"
                      className="align-self-center"
                    >
                      {isLoading ? (
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>Enviar</>
                      )}
                    </Button>
                  </Row>
                </FormContent>
              </Row>
            </FormBox>
          </Col>
        </Col>
        <InfoModal
          openModal={isOpenModal}
          onClose={handleOpenModal}
          titleText={modalContent[0]}
          noGreenDot
          exclamationMark
          textContent={<React.Fragment>{modalContent[1]}</React.Fragment>}
        />
      </Col>
    </Row>
  )
}

export default Contact
