import React from "react"
import { Col, Modal, Row } from "react-bootstrap"
import styled from "styled-components"
import { device } from "../../utils"
import { Box } from "../Core"

const ModalStyled = styled(Modal)`
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    max-height: initial;
    margin: 0;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    padding-top: 15px;
    background: rgba(0, 36, 50, 0.5);
    @media ${device.lg} {
      padding-top: 30px;
    }
  }
`

const InfoModal = props => {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <ModalStyled {...props} size="lg" centered show={props.openModal}>
      <Modal.Body className="text-center position-relative">
        <div
          className={`h-100 d-flex align-items-center w-100 justify-content-center`}
        >
          <Box
            css={
              "width: 560px; height:auto; background-color:white; border-radius: 8px; padding: 40px;"
            }
          >
            <Row>
              <Col xs={12} css={"text-align:right;"}>
                <span
                  css={`
                    padding: 20px 15px;
                    background: #dbf2fc;
                    border-radius: 300px;
                    :hover {
                      cursor: pointer;
                      color: #0984b5;
                    }
                  `}
                  onClick={handleClose}
                >
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    css={`
                      fill: currentcolor;
                      vertical-align: middle;
                      height: 1rem;
                      width: 1rem;
                    `}
                  >
                    <path
                      d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </Col>
              <Col
                xs={12}
                css={
                  "text-align:left; margin-top:20px; padding-right:200px !important; "
                }
              >
                {props.exclamationMark && (
                  <span
                    css={`
                      font-style: normal;
                      font-weight: bold;
                      font-size: 20px;
                      line-height: 30px;
                      color: ${({ theme }) => theme.colors.green};
                      letter-spacing: 0.04em;
                    `}
                  >
                    ¡
                  </span>
                )}
                <span
                  css={`
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 30px;
                    color: ${({ theme }) => theme.colors.dark};
                    letter-spacing: 0.04em;
                  `}
                >
                  {props.titleText}
                </span>
                {props.exclamationMark && (
                  <span
                    css={`
                      font-style: normal;
                      font-weight: bold;
                      font-size: 20px;
                      line-height: 30px;
                      color: ${({ theme }) => theme.colors.green};
                      letter-spacing: 0.04em;
                    `}
                  >
                    !
                  </span>
                )}
                {!props.noGreenDot && (
                  <span
                    css={`
                      font-style: normal;
                      font-weight: bold;
                      font-size: 20px;
                      line-height: 30px;
                      color: ${({ theme }) => theme.colors.green};
                      letter-spacing: 0.04em;
                    `}
                  >
                    .
                  </span>
                )}
              </Col>
              <Col xs={12} css={"text-align:left; margin-top:20px;"}>
                {props.textContent}
              </Col>
            </Row>
          </Box>
        </div>
      </Modal.Body>
    </ModalStyled>
  )
}

export default InfoModal
