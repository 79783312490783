// extracted by mini-css-extract-plugin
export var backgroundBox = "howitworks-module--background-box--ot1+v";
export var backgroundBoxMobile = "howitworks-module--background-box-mobile--DlUx1";
export var blueColorText = "howitworks-module--blue-color-text--FnFGx";
export var boldText = "howitworks-module--bold-text--5QslA";
export var budget = "howitworks-module--budget--TZf0C";
export var factcilUrl = "howitworks-module--factcil-url--D8cV9";
export var iconBoxLeftMd = "howitworks-module--icon-box-left-md--bdMeS";
export var iconBoxRightMd = "howitworks-module--icon-box-right-md--tK5Ej";
export var iconImage = "howitworks-module--icon-image--vRwC5";
export var image = "howitworks-module--image--vGJgj";
export var imageBox = "howitworks-module--image-box--IvTbb";
export var imageDescriptionText = "howitworks-module--image-description-text--sU802";
export var imageWrapper = "howitworks-module--image-wrapper--J6k2H";
export var imageWrapperBlue = "howitworks-module--image-wrapper-blue--WDmzA";
export var lastStepBackgroundBox = "howitworks-module--last-step-background-box--apJCk";
export var lastStepBackgroundBoxMobile = "howitworks-module--last-step-background-box-mobile--JEfVN";
export var leftBackgroundBoxMobile = "howitworks-module--left-background-box-mobile--Se7xu";
export var leftIconBox = "howitworks-module--left-icon-box--k+ujZ";
export var linkText = "howitworks-module--link-text--qoxli";
export var linkTextWithAction = "howitworks-module--link-text-with-action--tE4XG";
export var linkWrapper = "howitworks-module--link-wrapper--8VdN1";
export var listItem = "howitworks-module--list-item--ilxWe";
export var listItemMd = "howitworks-module--list-item-md--isnPM";
export var normalText = "howitworks-module--normal-text--fEskl";
export var piggyIconBox = "howitworks-module--piggy-icon-box--6i20B";
export var sectionSubtitle = "howitworks-module--section-subtitle--Xl6Tv";
export var sectionSubtitleActive = "howitworks-module--section-subtitle-active--N+-Kg";
export var sectionSubtitleContainer = "howitworks-module--section-subtitle-container--0CWgt";
export var sectionSubtitleMd = "howitworks-module--section-subtitle-md--995Pb";
export var sectionSubtitleMdBlue = "howitworks-module--section-subtitle-md-blue--psSSG";
export var sectionSubtitleMdGreen = "howitworks-module--section-subtitle-md-green--SSLYG";
export var sectionSubtitleNormal = "howitworks-module--section-subtitle-normal--iUBah";
export var sectionSubtitleRow = "howitworks-module--section-subtitle-row--AwWWo";
export var sectionTitle = "howitworks-module--section-title--YeBcQ";
export var sectionTitleDot = "howitworks-module--section-title-dot--AqCT-";
export var sectionTitleWrapper = "howitworks-module--section-title-wrapper--pzQoN";
export var stepImage = "howitworks-module--step-image--9s3bC";
export var stepImageLastStep = "howitworks-module--step-image-last-step--RFXTb";
export var stepImageMd = "howitworks-module--step-image-md--CSJwI";
export var stepThreeBox = "howitworks-module--step-three-box--OHiK4";
export var stepThreeBoxText = "howitworks-module--step-three-box-text--vvee3";
export var textWrapper = "howitworks-module--text-wrapper--zYU5n";
export var topIconBox = "howitworks-module--top-icon-box--KgMSD";